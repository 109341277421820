import * as React from "react"
import Layout from "../components/Layout"

class Photo extends React.Component {
  render() {
    
    return (
      <Layout>
        <div>
          photo
        </div>
      </Layout>
    )
  }
}

export default Photo;
